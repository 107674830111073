<template>
    <div class="collapse show">
    <div class="filter-content">
        <form @submit.prevent="applyFilters">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="form-row">
                        <div class="form-group col-md-3 apiAuto">
                            <label for="input2" class="col-form-label">{{ activeLanguage.apiName }}</label>
                            <v-autocomplete
                                :min-len="1"
                                :items="codeList"
                                :component-item='codeTemplate'
                                @update-items="updateCode"
                                @input="(e) => showCodes = !!e"
                                :get-label="getCodeLabel"
                                @item-selected="apiNameIsSelected"
                                @change="apiNameIsChanged"
                                v-model="apiName"
                            >
                            </v-autocomplete>
                        </div>
                        <div class="form-group col-md-3 errAuto">
                            <label for="input5" class="col-form-label">{{ activeLanguage.code }}</label>
                            <v-autocomplete
                                :disabled="!showCodes"
                                :min-len="1"
                                :items="errorList"
                                :component-item='errorTemplate'
                                @update-items="updateError"
                                :get-label="getCodeLabel"
                                @item-selected="errCodeIsSelected"
                                v-model="code"
                            >
                            </v-autocomplete>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="col-form-label" style="color:transparent;pointer-events: none;">{{ activeLanguage.err }}</div>
                            <label class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" v-model="form.err">
                                <span class="custom-control-indicator"></span>
                                <span>{{ activeLanguage.err }}</span>
                            </label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="input7" class="col-form-label">{{ searchTextLanguage }}</label>
                            <input
                                class="form-control mr-sm-2"
                                v-model="form.searchLogsVal"
                                type="text"
                            >
                        </div>
                        <div class="form-group col-md-3" v-if="manageOrdersPermission">
                            <label for="input6" class="col-form-label">{{ activeLanguage.client }}</label>
                            <v-autocomplete
                                :min-len="2"
                                v-model="form.client"
                                :items="clients"
                                :get-label="getLabel"
                                :component-item='itemTemplate'
                                @update-items="updateClientList"
                                @item-selected="clientSelected"
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <a class="btn btn-secondary" style="margin-right: 10px;" @click.prevent="clearFilters">{{ activeLanguage.clearBtn }}</a>
                <button type="submit" class="btn">{{ activeLanguage.applyBtn }}</button>
            </div>
        </form>
    </div>
    </div>
</template>

<script>
    import {mapGetters} from  "vuex";

	import ClientItemTemplate from '../../common/reusable/ClientItemTemplate.vue'
	import ErrorListTemplate from './AutocompleteTemplates/ErrorListTemplate.vue'
	import CodeTemplate from './AutocompleteTemplates/CodeTemplate.vue'

	export default {
		name: "ApiLogFilter",
		props: {
			activeLanguage: {
				type: Object,
				required: true
			},
            searchTextLanguage: {
                type: String,
                required: true
            },
            showFilter: {
                type: Boolean,
                required: true
            }
		},
        data() {
			return {
				form: {},
				clients: [],
				client: null,
				itemTemplate: ClientItemTemplate,
				errorTemplate: ErrorListTemplate,
				codeTemplate: CodeTemplate,
				errorList: [],
				errorFullList: [],
				codeList: [],
				codeFullList: [],
				showCodes: false,
				apiName: '',
                code: ''
            }
        },
        methods: {
	        applyFilters() {
		        this.$emit('onFiltersApply', this.form)
            },
	        clearFilters() {
		        this.form = {}
		        this.client = null
		        this.clients = []
                this.code = ''
                this.apiName = ''
		        this.$emit('onFiltersApply', this.form)
                this.getErrorList()
	        },
	        updateClientList(val) {
		        billinkAxios
			        .post('app/clients/list', { username: val })
			        .then(({data}) => this.clients = data.clients)
			        .catch(err => console.error(err))
	        },
	        clientSelected(val) {
		        this.form.client = val
		        this.form.client_id = val.id
	        },
            apiNameIsChanged(val) {
	            if (!val.length) { this.codeList = this.codeFullList }
            },
            apiNameIsSelected(val) {
	            let errList = this.errorFullList
		            .filter((item) => item.title.toLowerCase()
			            .includes(val.toLowerCase()))

	            this.errorList = Object.keys(errList[0].items)

                this.form.apiName = val
            },
	        errCodeIsSelected(val) {
	        	this.form.errCode = val
            },
	        getLabel (item) {
		        return !!item ? item.username : ''
	        },
	        getCodeLabel(item) {
		        return item
	        },
	        getErrorList() {
		        billinkAxios
			        .post('app/api-log/error-list')
			        .then(({data}) => {
			        	for(let i in data) {
                            this.codeFullList.unshift(i)
					        this.errorFullList.unshift({
                                title: i,
                                items: data[i]
                            })
                        }
                        this.codeList = this.codeFullList
                        this.clients = []
			        })
			        .catch(err => console.error(err))
	        },
	        updateCode(val) {
		        this.codeList = []
                    .concat(
                    	this.codeFullList
                            .filter(
                                (item) => item
                                    .toLowerCase()
                                    .includes(val.toLowerCase())
                            )
                        )
            },
	        updateError(val) {
		        this.errorList = this.errorList.filter(
		        	(item) => item.includes(val + '')
                )
	        },
        },
        mounted() {
			this.getErrorList()
        },
        components: {
			ClientItemTemplate,
			ErrorListTemplate,
			CodeTemplate
		},
		watch: {
			client: function(newVal, oldVal) {
				if (!newVal) { delete this.form.client_id }
			}
		},
		computed: {
			...mapGetters({
				manageOrdersPermission: 'userManage_orders'
			}),
		},
	}
</script>

<style lang="scss">
    .apiAuto, .errAuto {
        .v-autocomplete {
            z-index: 1000;
        }
    }
    .v-autocomplete-input-group input[disabled] {
        background-color: #ddd !important;
    }
</style>

<style scoped lang="scss">
    .custom-control {
            min-height: 1.8rem !important;
            padding-top: 8px;
            cursor: pointer;
        .custom-control-indicator {
            top: 0.6rem;
        }
    }
</style>