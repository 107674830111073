<template>
    <div class="clientItem">
        <div>
            <div>
                {{item}}
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: "ErrorListTemplate",
		props: ['item']
	}
</script>

<style scoped>

</style>