<template>
    <div class="main apidoc">
        <div class="container">
            <div class="title">
                <h1>{{ activeLanguage.apiLogs.title }}</h1>
            </div>
            <div class="filter-bar-container">
                <div class="filter-bar">
                    <div>
                        <button type="button" class="btn btn-secondary" @click="toggleFilter">
                            <i class="filter-icon fa fa-filter fa-lg" aria-hidden="true"></i>
                            {{ activeLanguage.apiLogs.apiLogsFilter.filterBtn }}
                            <i class="fa fa-caret-down caret" aria-hidden="true"></i>
                        </button>
                        <form class="form-inline float-right" @submit.prevent="onFiltersApply()" v-if="!showLogsFilter">
                            <input
                                class="form-control mr-sm-2"
                                v-model="searchLogsVal"
                                type="text"
                                :placeholder="activeLanguage.apiLogs.searchText"
                            >
                            <button class="btn nopadding" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
                        </form>
                    </div>
                </div>
                <ApiLogFilter
                    :activeLanguage="activeLanguage.apiLogs.apiLogsFilter"
                    :searchTextLanguage="activeLanguage.apiLogs.searchText"
                    :showFilter="showLogsFilter"
                    @onFiltersApply="onFiltersApply"
                    v-show="showLogsFilter"
                />
            </div>
            <ApiLogsTable
                :activeLanguage="activeLanguage.apiLogs"
                :logs="logs"
                :all="true"
            />
            <b-pagination
                align="center"
                :prev-text="activeLanguage.apiLogs.pagPrev"
                :next-text="activeLanguage.apiLogs.pagNext"
                :limit="6"
                @input="getLogs(pagData.currentPage)"
                :total-rows="pagData.maxOrders"
                v-model="pagData.currentPage"
                :per-page="20"
                v-if="pagData.maxOrders > 20"
                :disabled="disablePagination"
            />
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"
	import vkbeautify from 'vkbeautify'

	import ApiLogsTable from '../../components/apiView/ApiDocView/ApiLogsTable'
	import ApiLogFilter from '../../components/apiView/ApiDocView/ApiLogFilter'

	export default {
		name: "ApiLogsList",
        data() {
			return {
				logs: [],
				pagData: {
					currentPage: 1,
					maxOrders: 0,
				},
				disablePagination: false,
				showLogsFilter: false,
				searchLogsVal: '',
				activeFilters: {}
            }
        },
        mounted() {
			this.getAllLogs()
        },
        methods: {
	        onFiltersApply(form = {}) {
                let searchLogs = form.searchLogsVal ? form.searchLogsVal : this.searchLogsVal
                
		        this.activeFilters = {
			        client_id: form.client_id,
			        content: searchLogs,
			        api_name: form.apiName,
			        error_codes: form.err,
			        code: form.errCode
		        }

		        this.getLogs()
	        },
			getLogs(page = 1) {
				this.disablePagination = true
                
				let params = { 
                        page,
                        filter: this.activeFilters 
                        }


				billinkAxios
					.get('/app/api-log/list', { params })
					.then(({data}) => {
						this.pagData.currentPage = data.page
						this.pagData.maxOrders = parseFloat(data.total_logs)

						this.logs = data.log_list.map((log) => {
							log.request = vkbeautify.xml(log.request.trim())
							log.response = vkbeautify.xml(log.response.trim())

							return log
						})
                    })
					.catch(err => console.error(err))
					.then(() => {
						this.disablePagination = false
                    })
            },
            getAllLogs() {
                this.disablePagination = true
                billinkAxios
					.get('/app/api-log/list')
					.then(({data}) => {
						this.pagData.currentPage = data.page
						this.pagData.maxOrders = parseFloat(data.total_logs)

						this.logs = data.log_list.map((log) => {
							log.request = vkbeautify.xml(log.request.trim())
							log.response = vkbeautify.xml(log.response.trim())

							return log
						})
                    })
					.catch(err => console.error(err))
					.then(() => {
						this.disablePagination = false
                    })
            },
	        toggleFilter() {
                this.showLogsFilter = !this.showLogsFilter
            }
        },
        components: {
	        ApiLogsTable,
	        ApiLogFilter
        },
		computed: {
			...mapGetters({
				activeLanguage: 'getApiDocLanguage'
			}),
		},
	}
</script>

<style>
    .apidoc .pagination .page-item.active .page-link {
        color: #ffffff !important;
        background-color: #4db5af !important;
        border-color: #4db5af !important;
    }
</style>